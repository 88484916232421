import './app.css'

function App() {
  return (
    <div className='app'>
      <div>
        <img
          className='logo'
          alt='logo for squidlyfe.com'
          src='https://static.squidlyfe.com/squidlyfe-logo-zomg.svg'
        />
        <h1>Coming Soon</h1>
      </div>
    </div>
  )
}

export default App
